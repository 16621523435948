// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-get-involved-js": () => import("./../../../src/pages/get-involved.js" /* webpackChunkName: "component---src-pages-get-involved-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-submit-tip-js": () => import("./../../../src/pages/submit-tip.js" /* webpackChunkName: "component---src-pages-submit-tip-js" */),
  "component---src-pages-win-js": () => import("./../../../src/pages/win.js" /* webpackChunkName: "component---src-pages-win-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-tip-js": () => import("./../../../src/templates/tip.js" /* webpackChunkName: "component---src-templates-tip-js" */),
  "component---src-templates-tip-list-js": () => import("./../../../src/templates/tip-list.js" /* webpackChunkName: "component---src-templates-tip-list-js" */)
}

